@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

/* purgecss start ignore */
@import 'tailwindcss/base';
@import './base.css';
@import './nprogress.css';
@import './code.css';

@import 'tailwindcss/components';
@import './theme-toggle.css';
/* purgecss end ignore */

@tailwind utilities;

:root {
  --color-yellow: #ffde11;
}

.readmoreBtn {
  margin-top: 20px;
}
.posts {
  margin-top: 40px;
  margin-bottom: 80px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}

.posts .post {
  padding: 30px;
  border: 1px solid #292929;
  color: #292929;
}

.posts .post h2 {
  font-size: 21px;
  margin-bottom: 20px;
}

.posts .post p {
  font-size: 16px;
}

/** END POSTS STYLE **/

@media screen and (max-width: 768px) {
  .posts {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .posts {
    grid-template-columns: repeat(1, 1fr);
  }
}

/** START HEADER STYLE **/

header {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center;
	padding: 80px 0; */
}

.title h1 {
  font-size: 25px;
  text-align: center;
  margin-top: 15px;
}

header .filters {
  width: 530px;
  max-width: 95%;
  margin: auto;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header .search {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 15px;
}

header .sort {
  width: 30%;
  display: flex;
  flex-direction: column;
}

header .filters input,
header .filters select {
  font-size: 15px;
  font-family: inherit;
  color: #292929;
  width: 100%;
  padding: 10px 15px;
  background-color: transparent;
  border: 1px solid #292929;
  outline: none;
}

header .filters input::placeholder {
  color: #292929;
}

/** END HEADER STYLE **/

#nprogress {
  @apply pointer-events-none;
}

#nprogress .bar {
  @apply bg-pink-500 fixed top-0 left-0 z-50 w-full h-1;
}

.dark-mode #nprogress .bar {
  @apply bg-yellow;
}

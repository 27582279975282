.theme-transition * {
  @apply transition duration-500 ease-in-out;
}

.theme-toggle {
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  width: 40px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background: none;
  padding: 0;
  appearance: none;
}

.theme-toggle input {
  display: none;
}

.theme-toggle input + div {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: none;
  background-color: transparent;
  transform: scale(1);
  transition: all 0.45s ease;
  overflow: hidden;
  box-shadow: inset 8px -8px 0px 0px #718096;
}

.theme-toggle input:checked + div {
  border: 4px solid #cbd5e0;
  background-color: #cbd5e0;
  transform: scale(0.55);
  transition: all 0.45s ease;
  overflow: visible;
  box-shadow: none;
}

.theme-toggle input + div:before {
  content: '';
  position: absolute;
  right: -9px;
  top: -9px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  transition: transform 0.45s ease;
  border: none;
  transform: translate(0, 0);
  opacity: 1;
}

.theme-toggle input:checked + div:before {
  border: 2px solid #cbd5e0;
  transform: translate(14px, -14px);
  opacity: 0;
}

.theme-toggle input + div:after {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: -4px 0 0 -4px;
  position: absolute;
  top: 50%;
  left: 50%;
  box-shadow: 0 -23px 0 #cbd5e0, 0 23px 0 #cbd5e0, 23px 0 0 #cbd5e0, -23px 0 0 #cbd5e0, 15px 15px 0 #cbd5e0,
    -15px 15px 0 #cbd5e0, 15px -15px 0 #cbd5e0, -15px -15px 0 #cbd5e0;
  transform: scale(0);
  transition: all 0.35s ease;
}

.theme-toggle input:checked + div:after {
  transform: scale(1);
}

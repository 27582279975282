main {
  margin-top: 70px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  @apply my-0 font-semibold font-sans text-gray-800;
}

.dark-mode h1,
.dark-mode h2,
.dark-mode h3,
.dark-mode h4,
.dark-mode h5,
.dark-mode h6 {
  @apply text-white;
}

h1 {
  @apply text-3xl;
}

h2 {
  @apply text-2xl text-pink-500;
}

h3 {
  @apply text-xl text-pink-500;
}

h4 {
  @apply text-base;
}

h5 {
  @apply text-xl;
}

h6 {
  @apply text-xl;
}

p {
  @apply font-sans m-0 mb-4 font-light text-base text-gray-900;
}

.dark-mode p {
  @apply text-gray-200;
}

p * {
  @apply text-base text-gray-900;
}

a {
  @apply text-pink-500 no-underline;
}

.dark-mode a {
  @apply text-yellow;
}

a:hover {
  @apply underline cursor-pointer;
}

details {
  @apply block;
}

strong {
  @apply font-semibold text-teal-600;
}

.dark-mode strong {
  @apply text-pink-500;
}

em {
  @apply italic text-teal-600;
}

.dark-mode em {
  @apply text-pink-500;
}

img {
  @apply border-none max-w-full bg-white rounded;
}

img[align='right'] {
  @apply pl-5;
}

img[align='left'] {
  @apply pr-5;
}

hr {
  @apply overflow-hidden bg-transparent border-transparent border-b border-solid bg-gray-400 mb-4;
}

.dark-mode hr {
  @apply bg-gray-700;
}

input {
  @apply m-0 overflow-visible;
}

input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  @apply m-0 appearance-none;
}

[type='checkbox'] {
  @apply box-border p-0;
}

table {
  @apply border-collapse;
  border-spacing: 0;
}

td,
th {
  @apply p-0;
}

details summary {
  @apply cursor-pointer;
}

ol,
ul {
  @apply pl-8 mt-0 mb-4 text-base;
}

ol {
  @apply list-decimal;
}

ul {
  @apply list-disc;
}

dd {
  @apply ml-0;
}

blockquote,
details,
dl,
table {
  @apply mt-0 mb-4;
}

blockquote p {
  @apply m-0 italic py-0 px-4 text-gray-700 border-l-4 border-solid border-pink-500;
}

.dark-mode blockquote p {
  @apply text-gray-400 border-yellow;
}

blockquote > :first-child {
  @apply mt-0;
}

blockquote > :last-child {
  @apply mb-0;
}

li {
  @apply break-all font-light text-gray-900;
}

.dark-mode li {
  @apply text-gray-200;
}

li > p {
  @apply mt-4;
}

li + li {
  @apply mt-1;
}

dl {
  @apply p-0;
}

dl dt {
  @apply p-0 mt-4 italic font-semibold text-lg;
}

dl dd {
  @apply py-0 px-4 mb-4;
}

table {
  @apply block w-full overflow-auto;
}

table th {
  @apply font-semibold;
}

table td,
table th {
  @apply py-2 px-3 border border-solid border-gray-400;
}

.dark-mode table td,
.dark-mode table th {
  @apply border-gray-600 text-gray-200;
}

table tr {
  @apply bg-white border-t border-solid border-gray-500;
}

.dark-mode table tr {
  @apply bg-gray-800 border-gray-600;
}

table tr:nth-child(2n) {
  @apply bg-gray-400;
}

.dark-mode table tr:nth-child(2n) {
  @apply bg-gray-700;
}

kbd {
  @apply font-mono text-lg inline-block leading-none text-gray-700 align-middle bg-gray-100 border border-solid border-gray-400 rounded shadow py-1 px-2;
}

.dark-mode kbd {
  @apply text-gray-200 bg-gray-800 border-gray-700;
}

code {
  @apply py-1 px-2 m-0 bg-gray-200 font-mono text-base rounded text-pink-500;
}

.dark-mode code {
  @apply bg-gray-800 text-yellow;
}

.dark-mode code {
  @apply text-yellow;
}
